<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Transférer des tokens à un tiers avec garanti d'envoi</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Le titulaire d'un compte veut transférer des tokens vers un autre compte mais il ne possède pas ce token.
			Il va donc utiliser un marché automatique pour convertir un token d'envoi vers un token de réception.
			<br /><br /> Le compte de réception doit déjà avoir le token de réception dans sa liste des tokens ou créer une ligne de confiance sur ce token.

			C'est une sécurité de la blockchain stellar qui protège contre l'envoi de token non sollicité.
			Si ce n'est pas le cas, le titulaire du compte de réception doit d'abord passer un ordre d'achat sur le token. <a href="trustline">(voir la procédure ici)</a>
			<br />
			<br /> Comme la valeur de conversion des tokens n'est pas connu à l'avance sur un marché automatique,
			la garanti d'envoi permet à l'envoyeur de savoir exactement combien de token sera débité.
			À l'inverse, le destinataire ne sait pas précisément le nombre de token qui sera crédité sur son compte.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Isabelle va envoyer 150 fcEURO à Juliette qui va recevoir du Berlcoin
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-6.png" />
				<br /> <br /> <b> Le compte de Isabelle</b><br />
			</div>
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/send-b-1.png" />
				<br /> <br /> <b> Le compte de Juliette</b><br />
			</div>
		</div>


		<h3 class="mt-12">
			Ordre de transfert de 150 fcEuro avec conversion en Berlcoin</h3>


		<p>Dans la section <b>Transfert</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-5.png" />
				<br /> <br />
				<b>Isabelle sélectionne le menu Transférer & payer, la liste de ses tokens apparait
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-4.png" /><br />
				<br />
				<b>Isabelle sélectionne le fcEURO, le menu des transferts apparait, elle choisie GARANTIE D'ENVOI
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-3.png" /><br />
				<br /> <br />
				<b>Le formulaire apparait, Isabelle selectionne le Berlcoin
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-2.png" /><br />
				<br /> <br />
				<b> puis indique le nombre de fcEURO à transférer, la clé publique de Juliette et valide
				</b> <br />
			</div>
		</div>

		<h3 class="mt-12">
			Situation des comptes après le transfert</h3>


		<div class="row ">
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/send-a-1.png" />
				<br /> <br />
				<b>Le compte d'Isabelle à été débité de 150 fcEURO
				</b> <br />
			</div>
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/send-b-2.png" />
				<br /> <br />
				<b>Le compte de Juliette à été crédité de 1.52 Berlcoin
				</b> <br />
			</div>

		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Isabelle a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de transfert, Juliette n'a rien payé
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
